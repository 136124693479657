import React from 'react'
import { Box, Typography, LinearProgress } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { FormatDate } from '../utils/DateUtils'

interface PositionBoxProps {
  actualPrice: number
  openTime: string
  openPrice: number
  tpPrice: number
  stoplossPrice: number
  amount: number
  currentPrice: number
  feePercentage?: number
}

const PositionBox: React.FC<PositionBoxProps> = ({
  actualPrice,
  openTime,
  openPrice,
  tpPrice,
  stoplossPrice,
  amount,
  currentPrice,
  feePercentage = 0.1
}) => {
  const amountInUSD = openPrice * amount
  const actualProfit = (currentPrice - openPrice) * amount
  const actualProfitPercent = ((currentPrice - openPrice) / openPrice) * 100
  const profitWithoutFee = actualProfit - (amountInUSD * feePercentage) / 100

  const calculateProgressTp = (price: number, targetPrice: number) => {
    if (price <= openPrice) return 0
    if (price >= tpPrice) return 100

    return Math.round((100 - ((tpPrice - price) / (tpPrice - openPrice)) * 100) * 100) / 100
  }

  const calculateProgressSL = (price: number, targetPrice: number) => {
    if (price >= openPrice) return 0
    if (price <= stoplossPrice) return 100

    return Math.round((100 + ((price - stoplossPrice) / (openPrice - stoplossPrice)) * -100) * 100) / 100
  }

  const tpProgress = calculateProgressTp(currentPrice, tpPrice)
  const slProgress = calculateProgressSL(currentPrice, stoplossPrice)

  return (
    <Box p={3} border={1} borderRadius={2} borderColor="grey.300">
      <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
        <Box display="flex" alignItems="center">
          <Typography variant="h6" gutterBottom>
            Position Details
          </Typography>
          <Typography variant="body2" color="textSecondary" ml={1}>
            {FormatDate(new Date(openTime).toLocaleString())}
          </Typography>
        </Box>

        <Typography variant="body2" color="textSecondary">
          actual price: <b>{actualPrice}</b>
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid size={6}>
          <Typography></Typography>
          <Typography variant="body2">Open Price: $ {openPrice}</Typography>
          <Typography variant="body2">Take Profit: $ {tpPrice}</Typography>
          <Typography variant="body2">Stoploss: $ {stoplossPrice}</Typography>
          <Typography variant="body2">
            Amount: {amount} / ${amountInUSD.toFixed(2)}
          </Typography>
        </Grid>

        <Grid size={6}>
          <Typography variant="body2">Current Profit: $ {actualProfit.toFixed(2)}</Typography>
          <Typography variant="body2">Current Profit %: {actualProfitPercent.toFixed(2)}%</Typography>
          <Typography variant="body2">Profit Without Fee: $ {profitWithoutFee.toFixed(2)}</Typography>
        </Grid>
      </Grid>

      <Box display="flex" alignItems="center" mt={3}>
        <Box width="50%" pr={1}>
          <Typography variant="body2" color="textSecondary" textAlign="right" fontWeight={'bold'}>
            Stop Loss ({slProgress}%)
          </Typography>
          <LinearProgress
            variant="determinate"
            value={slProgress}
            color="error"
            sx={{
              height: 10,
              borderRadius: 5,
              '& .MuiLinearProgress-bar': { backgroundColor: 'rgba(255,0,0,1)' }, // lighter red
              transform: 'rotate(180deg)'
            }}
          />
        </Box>

        <Box width="50%" pl={1}>
          <Typography variant="body2" color="textSecondary" textAlign="left" fontWeight={'bold'}>
            Take Profit ({tpProgress}%)
          </Typography>
          <LinearProgress
            variant="determinate"
            value={tpProgress}
            color="success"
            sx={{
              height: 10,
              borderRadius: 5,
              '& .MuiLinearProgress-bar': { backgroundColor: '#rgba(0,128,0,1)' } // lighter green
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default PositionBox
