import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import axios from 'axios'
import { API_URL } from '../const'
import { CycleStats } from '../interface/CycleStats'
import { CycleStatsTable } from '../components/CycleStatsTable'

interface HomeProps {
  botId: number | null
  isBotRun: boolean
}

const Stats: React.FC<HomeProps> = ({ botId }) => {
  const [cycleStats, setCycleStats] = useState<CycleStats>()

  const LoadCycleStats = async (botIdValue: number | null) => {
    if (botIdValue == null) return

    try {
      const response = await axios.get(API_URL, {
        params: {
          key: 'loadCycleStats',
          botId: botIdValue
        }
      })
      setCycleStats(response.data)
    } catch (error) {
      console.error('Error loading logs')
    }
  }

  // Run on page start
  useEffect(() => {
    LoadCycleStats(botId)
  }, [botId])

  return (
    <>
      <Box sx={{ gridColumn: { xs: 'span 1', sm: 'span 2' } }}>
        {cycleStats && <CycleStatsTable data={cycleStats}></CycleStatsTable>}
      </Box>
    </>
  )
}

export default Stats
