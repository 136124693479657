import React from 'react'
import { Box, Typography, Grid } from '@mui/material'

interface ProfitResult {
  createdAt: string // Format: 'YYYY-MM-DD HH:mm:ss'
  amount: number // Amount of the asset traded
  openPrice: number // Price at which the asset was opened
  netProfitResult: number // Profit result as a number
}

interface ProfitSummaryProps {
  data: ProfitResult[]
}

// Helper function to calculate profit and profit percentage for a specified period
const calculateProfit = (data: ProfitResult[], days: number) => {
  const now = new Date()
  const pastDate = new Date(now)
  pastDate.setDate(now.getDate() - days)

  // Filter entries within the specified date range
  const filteredData = data.filter((item) => new Date(item.createdAt) >= pastDate)
  // Calculate total profit and sum of individual profit percentages
  const totalProfit = filteredData.reduce((acc, item) => acc + item.netProfitResult, 0)
  const profitPercentSum = filteredData.reduce((acc, item) => {
    const tradeProfitPercent = item.openPrice > 0 ? (item.netProfitResult / (item.amount * item.openPrice)) * 100 : 0
    return acc + tradeProfitPercent
  }, 0)

  return {
    totalProfit: totalProfit.toFixed(2),
    profitPercentSum: profitPercentSum.toFixed(2)
  }
}

const ProfitSummary: React.FC<ProfitSummaryProps> = ({ data }) => {
  const profit7Days = calculateProfit(data, 7)
  const profit30Days = calculateProfit(data, 30)
  const profit90Days = calculateProfit(data, 90)
  const profit365Days = calculateProfit(data, 365)
  const totalTrades = data.length

  // Calculate win rate
  const winningTrades = data.filter((trade) => trade.netProfitResult > 0).length
  const winRate = totalTrades > 0 ? ((winningTrades / totalTrades) * 100).toFixed(2) + '%' : '0%'

  const renderProfit = (profit: { totalProfit: string; profitPercentSum: string }, label: string) => (
    <Grid item xs={12} sm={6} md={3} style={{ textAlign: 'center' }}>
      <Typography variant="body2" style={{ color: 'gray' }}>
        {label}:
        <span style={{ fontWeight: 'normal', color: parseFloat(profit.totalProfit) >= 0 ? 'green' : 'red' }}>
          ${profit.totalProfit}
        </span>
        <span
          style={{
            marginLeft: 4,
            fontWeight: 'normal',
            color: parseFloat(profit.profitPercentSum) >= 0 ? 'green' : 'red'
          }}
        >
          ({profit.profitPercentSum}%)
        </span>
      </Typography>
    </Grid>
  )

  return (
    <Box p={2} border={1} borderColor="grey.300" borderRadius={2}>
      <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
        <Box display="flex" alignItems="center">
          <Typography variant="h6" gutterBottom>
            Profit Summary
          </Typography>
        </Box>
        <Typography variant="body2" color="textSecondary" ml={1}>
          Trades: {totalTrades}
        </Typography>
        <Typography variant="body2" color="textSecondary" ml={1}>
          Win rate: {winRate} ({winningTrades} / {totalTrades - winningTrades})
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {renderProfit(profit7Days, 'Last 7 Days')}
        {renderProfit(profit30Days, 'Last 30 Days')}
        {renderProfit(profit90Days, 'Last 90 Days')}
        {renderProfit(profit365Days, 'Last 365 Days')}
      </Grid>
    </Box>
  )
}

export default ProfitSummary
