// src/App.tsx
import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom'
import {
  AppBar,
  Toolbar,
  Typography,
  Switch,
  Box,
  Button,
  Snackbar,
  Alert,
  TextField,
  MenuItem,
  IconButton,
  Menu,
  useTheme,
  useMediaQuery
} from '@mui/material'
import Home from './pages/Home'
import Settings from './pages/Settings'
import Logs from './pages/Logs'
import axios from 'axios'
import { API_URL } from './const'
import PingDisplay from './components/PingDisplay'
import Login from './pages/Login'
import MenuIcon from '@mui/icons-material/Menu'
import Stats from './pages/Stats'

const App: React.FC = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [botList, setBotList] = useState<Bot[]>([])
  const [isBotRunning, setIsBotRunning] = useState(false)
  const [isLastCycle, setIsLastCycle] = useState(false)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success')
  const [selectedBotId, setSelectedBotId] = useState(1) // Default selected value
  const [ping, setPing] = useState<string | null>(null)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedBotId(event.target.value as number) // Update state on selection change
  }

  interface Bot {
    id: number
    name: string
  }

  useEffect(() => {
    // Check if user is already authenticated (e.g., after page reload)
    const authStatus = localStorage.getItem('isAuthenticated') === 'true'
    setIsAuthenticated(authStatus)
  }, [])

  const handleLogout = () => {
    localStorage.removeItem('isAuthenticated') // Clear login status
    setIsAuthenticated(false)
  }

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleBotChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedBotId(Number(event.target.value))
  }

  const GetPing = async (botId: number | undefined) => {
    if (botId === undefined) return

    try {
      const response = await axios.get(API_URL, {
        params: {
          key: 'getPing',
          botId: botId
        }
      })
      setPing(response.data)
    } catch (error) {
      console.error('Load ping fail')
    }
  }

  const LoadBotList = async () => {
    try {
      const response = await axios.get(API_URL, {
        params: {
          key: 'loadBotList'
        }
      })
      setBotList(response.data)
    } catch (error) {
      setSnackbarMessage('Error loading bots')
      setSnackbarSeverity('error')
      setSnackbarOpen(true)
    }
  }

  const LoadBotRun = async (botId: number | undefined) => {
    if (botId === undefined) return

    try {
      const response = await axios.get(API_URL, {
        params: {
          key: 'loadBotRun',
          botId: botId
        }
      })
      setIsBotRunning(response.data)
    } catch (error) {
      setSnackbarMessage('Error loading bots')
      setSnackbarSeverity('error')
      setSnackbarOpen(true)
    }
  }

  const LoadBotLastCycle = async (botId: number | undefined) => {
    if (botId === undefined) return

    try {
      const response = await axios.get(API_URL, {
        params: {
          key: 'loadBotLastCycle',
          botId: botId
        }
      })
      setIsLastCycle(response.data)
    } catch (error) {
      setSnackbarMessage('Error loading bots')
      setSnackbarSeverity('error')
      setSnackbarOpen(true)
    }
  }

  const SaveBotRun = async (botRun: boolean) => {
    try {
      const response = await axios.get(API_URL, {
        params: {
          key: 'setBotRun',
          botId: selectedBotId,
          data: botRun ? 1 : 0
        }
      })
      if (response.data.status) {
        setSnackbarMessage(botRun ? 'Bot started' : 'Bot stopped')
        setSnackbarSeverity('success')
      } else {
        setSnackbarMessage(('Failed to' + botRun ? 'start bot ' : 'stop bot') + 'error:' + response.data.error)
        setSnackbarSeverity('error')
      }
      setSnackbarOpen(true)
    } catch (error) {
      setSnackbarMessage('Failed to' + botRun ? 'start bot' : 'stop bot')
      setSnackbarSeverity('error')
      setSnackbarOpen(true)
    }
  }

  const SaveLastCycle = async (lastCycle: boolean) => {
    try {
      const response = await axios.get(API_URL, {
        params: {
          key: 'setLastCycle',
          botId: selectedBotId,
          data: lastCycle ? 1 : 0
        }
      })
      if (response.data.status) {
        setSnackbarMessage(lastCycle ? 'Last cycle was enabled' : 'Last cycle was disabled')
        setSnackbarSeverity('success')
      } else {
        setSnackbarMessage(
          ('Failed to' + lastCycle ? 'enable last cycle' : 'disable last cycle') + 'error:' + response.data.error
        )
        setSnackbarSeverity('error')
      }
      setSnackbarOpen(true)
    } catch (error) {
      setSnackbarMessage('Failed to' + lastCycle ? 'enable last cycle' : 'disable last cycle')
      setSnackbarSeverity('error')
      setSnackbarOpen(true)
    }
  }

  const handleRunToggle = () => {
    const newRunState = !isBotRunning
    setIsBotRunning(newRunState)
    SaveBotRun(newRunState)

    // Update the snackbar message based on the new state
    if (newRunState) {
      setSnackbarMessage('Bot started successfully')
      setSnackbarSeverity('success')
    } else {
      setSnackbarMessage('Bot stopped')
      setSnackbarSeverity('error') // You can use 'success' if you prefer
    }

    setSnackbarOpen(true) // Show the snackbar
  }

  const handleLastCycleToggle = () => {
    const newLastCycleState = !isLastCycle
    setIsLastCycle(newLastCycleState)
    SaveLastCycle(newLastCycleState)

    // Update the snackbar message based on the new state
    setSnackbarMessage(newLastCycleState ? 'Last Cycle enabled' : 'Last Cycle disabled')
    setSnackbarSeverity(newLastCycleState ? 'success' : 'error') // Customize the severity if needed
    setSnackbarOpen(true) // Show the snackbar
  }

  const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') return
    setSnackbarOpen(false)
  }

  useEffect(() => {
    LoadBotList()
  }, [])

  useEffect(() => {
    const intervalPing = setInterval(() => {
      GetPing(selectedBotId)
    }, 1000)
    return () => clearInterval(intervalPing)
  }, [selectedBotId, GetPing])

  useEffect(() => {
    // console.log('ping changed [OK] ' + ping)
    LoadBotRun(selectedBotId)
    LoadBotLastCycle(selectedBotId)
  }, [ping, selectedBotId])

  return (
    <Router>
      {isAuthenticated ? (
        <Box>
          <AppBar position="static" color="default">
            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
              {/* Conditional rendering based on screen size */}
              {isMobile ? (
                // Hamburger Menu for Mobile View
                <Box>
                  <IconButton edge="start" color="inherit" onClick={handleMenuClick}>
                    <MenuIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                  >
                    <MenuItem component={Link} to="/" onClick={handleMenuClose}>
                      Home
                    </MenuItem>
                    <MenuItem component={Link} to="/settings" onClick={handleMenuClose}>
                      Settings
                    </MenuItem>
                    <MenuItem component={Link} to="/logs" onClick={handleMenuClose}>
                      Logs
                    </MenuItem>
                    <MenuItem component={Link} to="/stats" onClick={handleMenuClose}>
                      Stats
                    </MenuItem>
                    <MenuItem>
                      {botList.length > 0 && selectedBotId && (
                        <TextField
                          label="Select Bot"
                          select
                          fullWidth
                          variant="standard"
                          value={selectedBotId || ''}
                          onChange={handleChange}
                        >
                          {botList?.map((bot) => (
                            <MenuItem key={bot.id} value={bot.id} onClick={handleMenuClose}>
                              {bot.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleLogout()
                        handleMenuClose()
                      }}
                    >
                      Logout
                    </MenuItem>
                  </Menu>
                </Box>
              ) : (
                // Full toolbar for Desktop View
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Button component={Link} to="/" color="inherit">
                    Home
                  </Button>
                  <Button component={Link} to="/settings" color="inherit">
                    Settings
                  </Button>
                  <Button component={Link} to="/logs" color="inherit">
                    Logs
                  </Button>
                  <Button component={Link} to="/stats" color="inherit">
                    Stats
                  </Button>
                  <Button color="inherit" onClick={handleLogout}>
                    Logout
                  </Button>
                  {botList.length > 0 && selectedBotId && (
                    <TextField
                      label="Select Bot"
                      select
                      variant="standard"
                      value={selectedBotId || ''}
                      onChange={handleBotChange}
                      sx={{ minWidth: '150px' }}
                    >
                      {botList?.map((bot) => (
                        <MenuItem key={bot.id} value={bot.id}>
                          {bot.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </Box>
              )}

              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                {/* Displayed elements on toolbar */}
                <Switch checked={isBotRunning} onChange={handleRunToggle} />
                <Typography>{isBotRunning ? 'Run' : 'Stopped'}</Typography>
                <Switch checked={isLastCycle} onChange={handleLastCycleToggle} />
                <Typography>Last Cycle</Typography>
                <div style={{ marginLeft: 5 }}>
                  Ping: <PingDisplay timestamp={ping} limitSeconds={5} refreshAfterSeconds={1} />
                </div>
              </Box>
            </Toolbar>
          </AppBar>

          <Box sx={{ p: 2 }}>
            <Routes>
              <Route path="/" element={<Home botId={selectedBotId} isBotRun={isBotRunning} />} />
              <Route path="/settings" element={<Settings botId={selectedBotId} />} />
              <Route path="/logs" element={<Logs botId={selectedBotId} isBotRun={false} />} />
              <Route path="/stats" element={<Stats botId={selectedBotId} isBotRun={false} />} />
            </Routes>
          </Box>

          {/* Snackbar for success or error messages */}
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={() => setSnackbarOpen(false)}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Box>
      ) : (
        <Login onLogin={() => setIsAuthenticated(true)} />
      )}
    </Router>
  )
}

export default App
