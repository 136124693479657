import { Box, Typography, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material'
import { CycleStats } from '../interface/CycleStats'

export const CycleStatsTable: React.FC<{ data: CycleStats }> = ({ data }) => {
  return (
    <Box
      sx={{
        maxWidth: 200, // Makes the table compact
        // margin: '0 auto', // Centers the table horizontally
        mt: 4, // Adds top margin
        p: 2, // Adds padding inside the container
        boxShadow: 3, // Adds a shadow for better visuals
        borderRadius: 2, // Rounds the corners
        bgcolor: '#f9f9f9' // Adds a subtle background color
      }}
    >
      <Typography variant="h6" component="h2" align="center" gutterBottom>
        Cycle Stats
      </Typography>
      <TableContainer component={Paper} sx={{ boxShadow: 0 }}>
        <Table size="small" aria-label="Cycle Stats Table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  backgroundColor: '#575757', // Dark background
                  color: '#fff' // White text for contrast
                }}
              >
                Cycle
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  backgroundColor: '#575757', // Dark background
                  color: '#fff', // White text for contrast
                  textAlign: 'right'
                }}
              >
                Count
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(data).map(([key, value]) => (
              <TableRow key={key}>
                <TableCell>{key}</TableCell>
                <TableCell align="right">{value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
