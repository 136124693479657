import React, { useEffect, useRef, useState } from 'react'
import { Box, Typography } from '@mui/material'
import axios from 'axios'
import { API_URL } from '../const'
import { LogRecord } from '../interface/LogRecord'
import LogList from '../components/LogList'
import PositionBox from '../components/PositionBox'
import { Position } from '../interface/Position'
import { PriceUpdate } from '../interface/PriceUpdate'
import ProfitSummary from '../components/ProfitSummary'
import { ProfitResult } from '../interface/ProfitResult'
import { lastItemsDifferent } from '../utils/TypeUtils'

interface HomeProps {
  botId: number | null
  isBotRun: boolean
}

const Home: React.FC<HomeProps> = ({ botId, isBotRun }) => {
  const [lastLogs, setLastLogs] = useState<LogRecord[]>([])
  const [actualPosition, setActualPosition] = useState<Position>()
  const [actualPrice, setActualPrice] = useState<PriceUpdate | null>()
  const [allProfits, setAllProfits] = useState<ProfitResult[]>([])
  const [refreshTick, setRefreshTick] = useState<number>(0)
  const [selectedPair, setSelectedPair] = useState<string>()

  const LoadLogs = async (botIdValue: number | null) => {
    if (botIdValue == null) return

    try {
      const response = await axios.get(API_URL, {
        params: {
          key: 'loadLogs',
          botId: botIdValue,
          type: 'noType',
          messagesCount: 10
        }
      })
      // console.log(response.data)
      setLastLogs(response.data)
    } catch (error) {
      console.error('Error loading logs')
    }
  }

  const LoadActualPrice = async (botIdValue: number | null) => {
    if (botIdValue === null || selectedPair === undefined) return

    try {
      const response = await axios.get(API_URL, {
        params: {
          key: 'loadActualPrice',
          botId: botIdValue,
          pair: selectedPair
        }
      })
      setActualPrice(response.data)
    } catch (error) {
      console.error('Error loading price')
    }
  }

  const LoadActualPosition = async (botIdValue: number | null) => {
    if (botIdValue === null) return

    try {
      const response = await axios.get(API_URL, {
        params: {
          key: 'loadActualPosition',
          botId: botIdValue
        }
      })
      // console.log(response.data)
      setActualPosition(response.data)
    } catch (error) {
      console.error('Error loading position')
    }
  }

  const LoadAllProfits = async (botIdValue: number | null) => {
    if (botIdValue === null) return

    try {
      const response = await axios.get(API_URL, {
        params: {
          key: 'loadAllProfits',
          botId: botIdValue
        }
      })
      setAllProfits(response.data)
    } catch (error) {
      console.error('Error loading profits')
    }
  }

  const LoadSelectedPair = async (botIdValue: number | null) => {
    if (botIdValue === null) return

    try {
      const response = await axios.get(API_URL, {
        params: {
          key: 'loadPair',
          botId: botIdValue
        }
      })
      setSelectedPair(response.data)
    } catch (error) {
      console.error('Error loading profits')
    }
  }

  // Run on page start
  useEffect(() => {
    LoadSelectedPair(botId)
    LoadAllProfits(botId)
    LoadActualPosition(botId)
    LoadLogs(botId)
  }, [botId])

  const prevState = usePrevious(lastLogs)

  function usePrevious(value: LogRecord[]): LogRecord[] | undefined {
    const ref = useRef<LogRecord[] | undefined>()

    useEffect(() => {
      ref.current = value
    }, [value])

    return ref.current
  }

  // Do only if log changed
  useEffect(() => {
    if (lastItemsDifferent(prevState, lastLogs)) {
      // console.log('logs changed -----------')
      LoadAllProfits(botId)
      LoadActualPosition(botId)
    }
  }, [lastLogs, prevState, botId])

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRefreshTick((prev) => prev + 1)
    }, 1000)
    return () => clearInterval(intervalId)
  }, [botId])

  useEffect(() => {
    LoadLogs(botId)
    if (!isBotRun) return
    // if (!actualPosition) LoadActualPosition(botId)
    LoadActualPrice(botId)
  }, [refreshTick, isBotRun, botId])

  return (
    <>
      <Box>
        {allProfits && <ProfitSummary data={allProfits} />}
        {!allProfits && <Typography variant="h6">No profit data</Typography>}
      </Box>
      <Box>
        {/* <TradeList trades={sampleTradeData} /> */}
        {actualPosition && actualPrice && (
          <PositionBox
            actualPrice={actualPrice?.price ?? 0}
            openPrice={actualPosition?.openPrice}
            openTime={actualPosition?.createdAt?.date}
            tpPrice={actualPosition.tpPrice}
            stoplossPrice={actualPosition.stoplossPrice}
            amount={actualPosition.amount}
            currentPrice={actualPrice.price}
          />
        )}
        {!actualPosition && <Typography variant="h6">No position opened</Typography>}
      </Box>
      <Box>
        <Typography variant="h6">Log records</Typography>
        <LogList logs={lastLogs} dimOld={true} />
      </Box>
    </>
  )
}

export default Home
