import React, { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import axios from 'axios'
import { API_URL } from '../const'
import { LogRecord } from '../interface/LogRecord'
import LogList from '../components/LogList'

interface HomeProps {
  botId: number | null
  isBotRun: boolean
}

const Logs: React.FC<HomeProps> = ({ botId }) => {
  const [lastLogs, setLastLogs] = useState<LogRecord[]>([])

  const LoadLogs = async (botIdValue: number | null) => {
    if (botIdValue == null) return

    try {
      const response = await axios.get(API_URL, {
        params: {
          key: 'loadLogs',
          botId: botIdValue,
          type: 'noType',
          messagesCount: 1000
        }
      })
      setLastLogs(response.data)
    } catch (error) {
      console.error('Error loading logs: ' + error)
    }
  }

  // Run on page start
  useEffect(() => {
    LoadLogs(botId)
  }, [botId])

  return (
    <>
      <Box>
        <Typography variant="h6">Log records</Typography>
        <LogList logs={lastLogs} />
      </Box>
    </>
  )
}

export default Logs
