import React, { useState } from 'react'
import { HARD_CODED_PASSWORD, HARD_CODED_USERNAME } from '../pw'
import { Box, Button, Card, CardContent, TextField, Typography } from '@mui/material'

// Hardcoded credentials for demonstration purposes
const hcUsername = HARD_CODED_USERNAME
const hcPassword = HARD_CODED_PASSWORD

const Login: React.FC<{ onLogin: () => void }> = ({ onLogin }) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')

  const handleLogin = () => {
    if (username === hcUsername && password === hcPassword) {
      localStorage.setItem('isAuthenticated', 'true') // Save login status
      onLogin() // Call parent component's onLogin to update app state
    } else {
      setError('Invalid username or password')
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        bgcolor: '#f5f5f5',
        padding: 2
      }}
    >
      <Card sx={{ maxWidth: 400, width: '100%', boxShadow: 3, borderRadius: 2 }}>
        <CardContent>
          <Typography variant="h5" align="center" gutterBottom>
            Login
          </Typography>
          <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              label="Username"
              variant="outlined"
              fullWidth
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              label="Password"
              type="password"
              variant="outlined"
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button variant="contained" color="primary" fullWidth onClick={handleLogin}>
              Login
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}

export default Login
