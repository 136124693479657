// src/components/PingDisplay.tsx

import React, { useEffect, useState } from 'react'

interface PingDisplayProps {
  timestamp: string | null // Allow timestamp to be string or null
  limitSeconds: number
  refreshAfterSeconds: number
}

const PingDisplay: React.FC<PingDisplayProps> = ({ timestamp, limitSeconds, refreshAfterSeconds }) => {
  const [secondsDifference, setSecondsDifference] = useState(
    timestamp ? getSecondsDifference(parseTimestamp(timestamp)) : 0
  )

  useEffect(() => {
    if (!timestamp) return // Exit early if timestamp is null

    const intervalId = setInterval(() => {
      setSecondsDifference(getSecondsDifference(parseTimestamp(timestamp)))
    }, refreshAfterSeconds * 1000)

    return () => clearInterval(intervalId) // Clear interval on unmount
  }, [timestamp, refreshAfterSeconds])

  if (!timestamp) {
    return <span style={{ color: 'gray' }}>loading...</span>
  }

  const isOlder = secondsDifference > limitSeconds
  const formattedTime = formatDuration(secondsDifference)

  return <span style={{ color: isOlder ? 'red' : 'green', fontWeight: 'bold' }}>{formattedTime} s</span>
}

// Helper function to parse timestamp string or return current date if timestamp is null
function parseTimestamp(timestamp: string | null): Date {
  return timestamp ? new Date(timestamp.replace(' ', 'T')) : new Date()
}

// Helper function to calculate seconds difference
function getSecondsDifference(timestamp: Date): number {
  const now = new Date()
  return Math.floor((now.getTime() - timestamp.getTime()) / 1000)
}

// Helper function to format duration as a string
function formatDuration(seconds: number): string {
  return `${seconds}`
}

export default PingDisplay
