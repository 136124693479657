import { LogRecord } from '../interface/LogRecord'

// Converts a comma-separated string to an array of numbers
export function convertStringToArray(input: string): number[] {
  if (typeof input !== 'string') {
    // console.error('Input is not a string:', input)
    return input
  }

  // Remove surrounding square brackets if present
  input = input.trim()
  if (input.startsWith('[') && input.endsWith(']')) {
    input = input.slice(1, -1) // Remove the first and last characters (the brackets)
  }

  return input
    .split(',') // Split by commas
    .map((item) => parseFloat(item.trim())) // Trim whitespace and parse each item as a float
    .filter((item) => !isNaN(item)) // Filter out invalid numbers (NaN)
}

// Converts an array of numbers back to a comma-separated string
export function convertArrayToString(input: number[]): string {
  return input
    .map((item) => item.toString()) // Convert each number to a string
    .join(',') // Join with commas
}

export function lastItemsDifferent(a: LogRecord[] | undefined, b: LogRecord[]): boolean {
  if (!a || a.length === 0 || b.length === 0) return false
  return JSON.stringify(a[a.length - 1]) !== JSON.stringify(b[b.length - 1])
}
