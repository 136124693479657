export function FormatDate(dateString: string): string {
  const date = new Date(dateString)

  const day = date.getDate()
  const month = date.getMonth() + 1 // Months are zero-based
  const year = date.getFullYear()

  const hours = date.getHours().toString().padStart(2, '0')
  const minutes = date.getMinutes().toString().padStart(2, '0')
  const seconds = date.getSeconds().toString().padStart(2, '0')

  return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`
}

export const getSecondsDifference = (timestamp: string): number => {
  const givenTime = new Date(timestamp)
  const currentTime = new Date()
  const differenceInMilliseconds = currentTime.getTime() - givenTime.getTime()

  return Math.floor(differenceInMilliseconds / 1000)
}

export const formatDuration = (seconds: number): string => {
  if (seconds >= 3600) {
    // 1 hour
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const secs = seconds % 60
    return `${hours}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`
  } else if (seconds >= 60) {
    // 1 minute
    const minutes = Math.floor(seconds / 60)
    const secs = seconds % 60
    return `${minutes}:${secs.toString().padStart(2, '0')}`
  } else {
    return `${seconds}`
  }
}
